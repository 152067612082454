import React, { useState } from "react";
import { Button, notification, Spin } from "antd";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { toast } from "react-toastify";
import { postListApi } from "Api/apiFunctions";

const ListingImportModal = ({ importModal, setImportModal, getAllLists, handleCloseModal, formData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);  // Loading state for the API request


    console.log("dsfsfsdf", formData)

    const fields = [
        {
            label: 'First Name ',
            key: 'firstName',
            alternateMatches: ['First Name'],
            fieldType: {
                type: 'input',
            },
            example: '001200001'
        },
        {
            label: 'Owner Full Name',
            key: 'ownerFullName',
            alternateMatches: ['name', 'Name', 'owner name', 'ownername'],
            fieldType: {
                type: 'input'
            },
            example: 'John'
        },
        {
            label: 'Address',
            key: 'fullAddress',
            alternateMatches: ['location', 'Address', 'address'],
            fieldType: {
                type: 'input'
            },
            example: '1021 Louise RD WS 27107 NC'
        },
        {
            label: 'Bill Amount',
            key: 'billAmount',
            alternateMatches: ['Bill Amount'],
            fieldType: {
                type: 'input'
            },
            example: '453$'
        },
        {
            label: 'Tags',
            key: 'tags',
            alternateMatches: ['tag', 'tags', 'flag', 'flags', 'bill flag', 'bill flags'],
            fieldType: {
                type: 'input'
            },
            example: 'Deliquient, Taxable, Tax Deliquent'
        },
        {
            label: 'Current Due',
            key: 'currentDue',
            alternateMatches: ['Current Due'],
            fieldType: {
                type: 'input'
            },
            example: '4256'
        },
    ]

    const handleClose = () => {
        setIsOpen(false);
        setImportModal(false);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const onSubmit = async (data) => {
        try {

            const payload = formData?.selectedListId
                ? {
                    _id: formData?.selectedListId,
                    properties: data?.all,
                }
                : {
                    properties: data?.all,
                    name: formData?.name,
                    purchasedDate: formData?.purchasedDate,
                    purchasedFrom: formData?.purchasedFrom,
                };

            const response = await postListApi(payload);

            if (response?.data?.success) {
                await getAllLists();
                toast.success(response?.data?.message || "Records imported successfully!");
                handleClose();
            } else {
                toast.error(response?.message || "Failed to import records.");
            }
        } catch (error) {
            console.error("Error importing records:", error);
            toast.error("An error occurred while importing records.");
        } finally {
            await handleCloseModal();
        }
    };

    return (
        <>
            {/* <Button type="primary" onClick={handleOpen}>
        Import Listings
      </Button> */}

            <ReactSpreadsheetImport
                isOpen={handleOpen}
                onClose={handleClose}
                onSubmit={onSubmit}
                fields={fields}
                acceptedFileTypes={[
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-excel",
                ]}
                onFileLoaded={(fileData) => console.log("File data loaded:", fileData)}
            />

            {loading && (
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <Spin size="large" />
                </div>
            )}
        </>
    );
};

export default ListingImportModal;
