//importing layouts....
import Admin from 'layouts/Admin';
import UnAuth from 'layouts/Auth';

import Dashboard from "./views/Dashboard.js";
import Login from "./views/Login/Login";
import VerifyEmail from 'views/VerifyEmail/VerifyEmail'
import { faUserPlus, faAtom, faUserCheck, faDice, faHome, faGear, faLandmark, faLandmarkAlt, faTag, faList, faHand  } from '@fortawesome/free-solid-svg-icons'
import Profile from 'views/Profile/profile'
import Unauth from 'layouts/Auth';
import ForgotPassword from 'views/ForgotPassword/ForgotPassword';
import ResetPassword from 'views/ResetPassword/ResetPassword';
import SetPassword from 'views/SetPassword/SetPassword';
import Permissions from 'views/AdminStaff/permissions/permissionsListingComponent'
import Staff from 'views/AdminStaff/staff/staffListingComponent'
import Property from './views/Property/PropertyListing'
import PropertyFilesListing from 'views/Property/PropertyFilesListing'
import Setting from 'views/Settings/SiteSetting'
import TagsListing from 'views/Tags/TagsListing.jsx';
import ListingPage from 'views/Listing/ListingPage.jsx';
import ListOfRecord from 'views/Listing/ListOfRecord.js';
import ScraperPage from 'views/Scrape/ScraperPage.js';


var routes = [
  {
    path: "/dashboard",
    layout: Admin,
    name: "Dashboard",
    icon: faAtom,
    access: true,
    exact: true,
    component: Dashboard,
    showInSideBar: true
  },
  {
    collapse: true,
    name: "Staff",
    state: "openStaff",
    icon: faUserPlus,
    showInSideBar: true,
    submenus: [
      {
        path: "/staff",
        layout: Admin,
        name: "Staff",
        icon: faUserCheck,
        access: true, exact: true,
        component: Staff,
        showInSideBar: true,
      },
      {
        path: "/roles",
        layout: Admin,
        name: "Roles",
        icon: faDice,
        access: true, exact: true,
        component: Permissions,
        showInSideBar: true,
      }
    ],
  },
  {
    path: "/records",
    layout: Admin,
    name: "Records",
    icon: faLandmark,
    access: true,
    exact: true,
    component: Property,
    showInSideBar: true
  },
  {
    path: "/tags",
    layout: Admin,
    name: "Tags",
    icon: faTag,
    access: true,
    exact: true,
    component: TagsListing,
    showInSideBar: true
  },
  {
    path: "/lists",
    layout: Admin,
    name: "List",
    icon: faList,
    access: true,
    exact: true,
    component: ListingPage,
    showInSideBar: true
  },
  {
    path: "/scraping",
    layout: Admin,
    name: "Scraper",
    icon: faHand,
    access: true,
    exact: true,
    component: ScraperPage,
    showInSideBar: true
  },
  {
    path: "/property-files",
    layout: Admin,
    name: "Property Files",
    icon: faLandmarkAlt,
    access: true,
    exact: true,
    component: PropertyFilesListing,
    showInSideBar: true
  },
  {
    path: "/record-lists", // New Route
    layout: Admin,
    name: "List of Records",
    access: true,
    exact: true,
    component: ListOfRecord,
    showInSideBar: false, // Hidden from Sidebar
  },
  // {
  //   path: "/settings",
  //   layout: Admin,
  //   name: "Setting",
  //   icon: faGear,
  //   access: true,
  //   exact: true,
  //   component: Setting,
  //   showInSideBar: true
  // },


  ///////////////////
  {
    path: "/",
    layout: Unauth,
    name: "Login",
    icon: "nc-icon nc-chart-pie-35",
    access: true,
    exact: true,
    component: Login
  },
  {
    path: "/profile",
    layout: Admin,
    name: "Profile",
    icon: "nc-icon nc-circle-09",
    access: true, exact: true,
    component: Profile,
    showInSideBar: false,
  },
  {
    path: "/login",
    layout: UnAuth,
    name: "Login",
    mini: "LP",
    component: Login,
  },
  {
    path: "/forgot-password",
    layout: UnAuth,
    name: "Forgot Passowrd",
    mini: "FP",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    layout: UnAuth,
    name: "Reset Passowrd",
    mini: "RP",
    component: ResetPassword,
  },
  {
    path: "/set-password",
    layout: UnAuth,
    name: "Set Passowrd",
    mini: "SP",
    component: SetPassword,
  },
  {
    path: "/verify-email/:id",
    layout: UnAuth,
    name: "Verify Email",
    mini: "SP",
    component: VerifyEmail,
  },
];

export default routes;
