import { updateRecordApi } from 'Api/apiFunctions';
import axios from 'axios';
import { ENV } from 'config/config';
import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Row, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { toast } from 'react-toastify';

const EditRecordModal = ({ visible, onClose, record, mode, fetchListRecord }) => {
    const [tagsOptions, setTagsOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const [property, setProperty] = useState({
        countyId: '',
        countyName: '',
        billNumber: '',
        oldBillNumber: '',
        ownerFullName: '',
        ownerFirstName: '',
        ownerLastName: '',
        ownerMiddleName: '',
        tags: record?.tags?.map(tag => {
            if (typeof tag === "object" && tag !== null) {
                return { label: tag.title, value: tag._id };
            }
            // Handle cases where tags are strings or invalid
            return { label: String(tag), value: String(tag) };
        }) || [],
        parcelId: '',
        fullAddress: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        county: '',
        billFlags: '',
        billAmount: '',
        currentDue: '',
    });

    useEffect(() => {
        if (record) {
            setProperty({
                ...property,
                ...record, // Pre-fill the form with `record` data when editing or viewing
            });
        }
    }, [record]);

    const isViewMode = mode === 'view'; // Determine if the modal is in View mode

    const fetchTags = async () => {
        try {
            const response = await axios.get(`${ENV.url}tags/`);

            if (response.data.success) {
                setTagsOptions(response.data.data); // Assuming `tags` is the array from the API response
            }
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    const formattedTags = tagsOptions?.map(tag => ({
        label: tag.title,
        value: tag._id,
    }));

    const handleTagChange = (selectedOptions) => {
        const newTags = Array.isArray(selectedOptions)
            ? selectedOptions.map((tag) => ({ title: tag.label, _id: tag.value }))
            : selectedOptions
                ? [{ title: selectedOptions.label, _id: selectedOptions.value }]
                : [];

        setProperty((prevState) => {
            const combinedTags = [...prevState.tags, ...newTags];
            const uniqueTags = Array.from(
                new Map(combinedTags.map((tag) => [tag._id, tag])).values()
            );

            return {
                ...prevState,
                tags: uniqueTags
            };
        });
    };



    useEffect(() => {
        fetchTags();
    }, []);


    const handleEdit = async () => {
        const payload = {
            ...property, // Spread all the property fields
            // edit: true,  // Add the `edit` flag
            _id: record?._id, // Include the `record_id`
        };

        try {
            // Make the API call
            const response = await updateRecordApi(payload);

            // Handle success response
            if (response.data.success) {
                toast.success(response.data.message);
                await onClose()
                await fetchListRecord()

                // Optionally reset form or update state
            }
        } catch (error) {
            // Handle error response
            toast.error(error.response?.data?.message || error.message);
        }
    };

    return (
        <div>
            <Container fluid>
                <Modal
                    className="modal-primary staff-modal"
                    id="admin-modal"
                    size="xl"
                    onHide={onClose}
                    show={visible}
                >
                    <Modal.Header className="justify-content-center">
                        <Row>
                            <div className="col-12">
                                <h4 className="mb-0 mb-md-3 mt-0">
                                    {mode === 'add' ? 'Add New Record' : mode === 'edit' ? 'Edit Record' : 'View Record'}
                                </h4>
                            </div>
                        </Row>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <Form>
                            <Row>
                                <Col md={8}>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>County </label>
                                                <Select
                                                    onChange={(event) =>
                                                        setProperty({
                                                            ...property,
                                                            countyId: event.value,
                                                            countyName: event.label,
                                                        })
                                                    }
                                                    placeholder="--- Select County ---"
                                                    isDisabled={isViewMode}
                                                    value={{
                                                        label: property.countyName,
                                                        value: property.countyId,
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Parcel Id </label>
                                                <Form.Control
                                                    placeholder="Enter Parcel Id"
                                                    disabled={isViewMode}
                                                    type="text"
                                                    name="parcelId"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            parcelId: e.target.value,
                                                        })
                                                    }
                                                    value={property.parcelId}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Bill Number </label>
                                                <Form.Control
                                                    placeholder="Enter Bill Number"
                                                    disabled={isViewMode}
                                                    type="text"
                                                    name="billNumber"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            billNumber: e.target.value,
                                                        })
                                                    }
                                                    value={property.billNumber}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Bill Amount ($)</label>
                                                <Form.Control
                                                    placeholder="Enter Bill Amount"
                                                    disabled={isViewMode}
                                                    type="number"
                                                    name="billAmount"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            billAmount: e.target.value,
                                                        })
                                                    }
                                                    value={property.billAmount}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Current Due ($)</label>
                                                <Form.Control
                                                    placeholder="Enter Current Due"
                                                    disabled={isViewMode}
                                                    type="number"
                                                    name="currentDue"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            currentDue: e.target.value,
                                                        })
                                                    }
                                                    value={property.currentDue}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>First Name </label>
                                                <Form.Control
                                                    placeholder="Enter First Name"
                                                    disabled={isViewMode}
                                                    type="text"
                                                    name="ownerFirstName"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            ownerFirstName: e.target.value,
                                                        })
                                                    }
                                                    value={ENV.capatilize(property.ownerFirstName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Last Name </label>
                                                <Form.Control
                                                    placeholder="Enter Last Name"
                                                    disabled={isViewMode}
                                                    type="text"
                                                    name="ownerLastName"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            ownerLastName: e.target.value,
                                                        })
                                                    }
                                                    value={ENV.capatilize(property.ownerLastName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <label>Middle Name </label>
                                                <Form.Control
                                                    placeholder="Enter Middle Name"
                                                    disabled={isViewMode}
                                                    type="text"
                                                    name="ownerMiddleName"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            ownerMiddleName: e.target.value,
                                                        })
                                                    }
                                                    value={ENV.capatilize(property.ownerMiddleName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <Form.Group>
                                                <label>Full Address </label>
                                                <Form.Control
                                                    placeholder="Enter Full Address"
                                                    disabled={isViewMode}
                                                    type="text"
                                                    name="fullAddress"
                                                    onChange={(e) =>
                                                        setProperty({
                                                            ...property,
                                                            fullAddress: e.target.value,
                                                        })
                                                    }
                                                    value={ENV.capatilize(property.fullAddress)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <label>Tags</label>
                                        <div className="tags-input-container">
                                            <Select
                                                placeholder="Enter tags and press Enter"
                                                type="text"
                                                isDisabled={isViewMode}
                                                options={formattedTags.map((tag) => ({
                                                    label: tag.label, // Ant Design expects `label`
                                                    value: tag.value, // Ant Design expects `value`
                                                }))}
                                                onChange={handleTagChange}
                                                onInputChange={(newValue) => {
                                                    // Handle input change for custom tag entry, but do not update tags here
                                                    if (newValue && newValue.trim()) {
                                                        setInputValue(newValue.trim()); // Store the current input value in state
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault(); // Prevent default Enter behavior
                                                        const value = inputValue.trim(); // Use the state-stored input value
                                                        if (value) {
                                                            const currentTags = property.tags || [];
                                                            // Check for duplicates
                                                            if (!currentTags.some((tag) => tag.title === value)) {
                                                                setProperty({
                                                                    ...property,
                                                                    tags: [
                                                                        ...currentTags,
                                                                        { title: value, _id: Date.now().toString() }, // Add custom tag
                                                                    ],
                                                                });
                                                            }
                                                            setInputValue(''); // Clear the input value state
                                                        }
                                                    }
                                                }}
                                            />
                                            <div className="tags-container mt-2">
                                                {property.tags && property?.tags?.map((tag, index) => (
                                                    <span key={tag?._id || index} className="tag-pill">
                                                        {tag?.title || tag?._id || tag}
                                                        {!isViewMode && (
                                                            <span
                                                                className="remove-tag"
                                                                onClick={() => {
                                                                    const newTags = property.tags.filter((_, i) => i !== index);
                                                                    setProperty({
                                                                        ...property,
                                                                        tags: newTags
                                                                    });
                                                                }}
                                                            >
                                                                ×
                                                            </span>
                                                        )}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <style jsx="true">{`
        .tags-input-container {
            width: 100%;
        }
        .tags-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
        .tag-pill {
            background: #e9ecef;
            border-radius: 16px;
            padding: 4px 12px;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
        .remove-tag {
            margin-left: 8px;
            cursor: pointer;
            font-weight: bold;
            font-size: 16px;
        }
        .remove-tag:hover {
            color: #dc3545;
        }
    `}</style>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-warning"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        {!isViewMode && (
                            <Button className="btn btn-info" onClick={handleEdit}>Save</Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
};

export default EditRecordModal;
