import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './routes';
import NotFound from "views/NotFound/NotFound";
import ListOfRecord from 'views/Listing/ListOfRecord';

class App extends Component {


    render() {
        return (
            <React.Fragment>
                <Routes>
                    {
                        routes.map((route, index) => {
                            if (route.path) {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={
                                            (
                                                <route.layout>
                                                    <route.component />
                                                </route.layout>
                                            )
                                            //   route.access ? (
                                            //     <route.layout>
                                            //       <route.component />
                                            //     </route.layout>
                                            //   ) : (
                                            //     <Navigate to="/login" replace />
                                            //   )
                                        }
                                    />
                                )
                            } else {
                                return route.submenus.map((subroute, subkey) => {
                                    if (subroute.path) {
                                        return (
                                            <Route
                                                key={index + subkey}
                                                path={subroute.path}
                                                element={
                                                    subroute.access ? (
                                                        <subroute.layout>
                                                            <subroute.component />
                                                        </subroute.layout>
                                                    ) : (
                                                        <Navigate to="/login" replace />
                                                    )
                                                }
                                            />
                                        )
                                    } else {
                                        return subroute.submenus.map((nestedsubroute, nestedsubkey) => {
                                            return (
                                                <Route
                                                    key={index + nestedsubkey}
                                                    path={nestedsubroute.path}
                                                    element={
                                                        nestedsubroute.access ? (
                                                            <nestedsubroute.layout>
                                                                <nestedsubroute.component />
                                                            </nestedsubroute.layout>
                                                        ) : (
                                                            <Navigate to="/login" replace />
                                                        )
                                                    }
                                                />
                                            )
                                        })
                                    }
                                })
                            }
                        })
                    }
                    <Route path='/record-lists' element={<ListOfRecord />} />
                    <Route path="*" element={<NotFound />} />

                </Routes>
            </React.Fragment>
        )



    }
}

export default App;
