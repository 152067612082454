import React, { useState, useEffect } from 'react';
import { Table, Select, Space, Card, Button } from 'antd';
import RangePicker from 'components/RangePicker/RangePicker'
import { useLocation } from 'react-router';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { getAllRecordListApi } from 'Api/apiFunctions';
import {
    // Button,
    // Card,
    Form,
    Row,
    Col,
    Accordion,
} from 'react-bootstrap'
import { ENV } from 'config/config';
import { deleteRecordApi } from 'Api/apiFunctions';
import { toast } from 'react-toastify';
import { getAllMatchRecordListApi } from 'Api/apiFunctions';
import EditRecordModal from './EditRecordModal';


const ListOfRecord = () => {
    const location = useLocation();
    const { recordId, keyName } = location.state || {};
    const [modalState, setModalState] = useState({
        isVisible: false,
        mode: "edit", // 'edit' or 'view'
        record: null,
    });

    const [search, setSearch] = useState({
        county: '',
        ownerName: '',
        billNumber: '',
        oldBillNumber: '',
        parcelId: '',
        address: '',
        tags: '',
        billAmountFrom: '',
        billAmountTo: '',
        currentDueFrom: '',
        currentDueTo: '',
    })
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 10, total: 0 });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchDisabled, setSerachDisabled] = useState(false)

    const handleFilterChange = (key, value) => {
        setSearch({ ...filters, [key]: value });
    };

    const handleListNameResponse = (response) => response?.data?.data?.listRecords || [];
    const handleMatchRecordResponse = (response) => response?.data?.data?.propertys || [];

    const fetchListRecord = async (page = 1, pageSize = 10) => {
        // setIsLoading(true);
        try {
            let response, listData;

            if (keyName === "listName") {
                response = await getAllRecordListApi(recordId, page, pageSize, search);
                listData = handleListNameResponse(response);
            } else if (keyName === "matchRecord") {
                response = await getAllMatchRecordListApi(recordId, page, pageSize, search);
                listData = handleMatchRecordResponse(response);
            }

            if (response?.data?.success) {
                const { pagination: apiPagination } = response.data.data;
                setRecords(listData);
                setPagination({
                    current: apiPagination.page,
                    pageSize: apiPagination.limit,
                    total: apiPagination.total,
                });
            }
        } catch (error) {
            console.error('Error fetching records:', error);
        } 
    };

    useEffect(() => {
        if (keyName) {
            fetchListRecord(pagination.current, pagination.pageSize);
        }
    }, [recordId, keyName, pagination.current, pagination.pageSize]);


    const handleTableChange = (pagination) => {
        fetchListRecord(pagination.current, pagination.pageSize);
    };

    const onSearch = async () => {
        setIsLoading(true);
        await fetchListRecord(pagination.current, pagination.pageSize);
        setIsLoading(false);
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault()
            fetchListRecord()
        }
    }

    const reset = async () => {
        setSearch({
            county: '',
            ownerName: '',
            tags: '',
            parcelId: '',
            address: '',
            billAmountFrom: '',
            billAmountTo: '',
            currentDueFrom: '',
            currentDueTo: '',
        });

        // Reset pagination to the first page
        setPagination({ currentPage: 1, pageSize: 10, total: 0 });

        // Fetch data without filters and reset pagination
        await fetchListRecord(1, 10);
    };

    const areAllKeysEmpty = obj => {
        return Object.values(obj).every(
            value => value === '' || value === null || value === undefined
        )
    }


    useEffect(() => {
        setSerachDisabled(
            !areAllKeysEmpty(search)
        )

    }, [search])


    const handleDelete = async (recordIds) => {
        const payload = {
            data: {
                listRecordIds: {
                    listRecordIds: recordIds,
                },
            },
        };

        try {
            const response = await deleteRecordApi(payload);
            if (response.data.success) {
                toast.success(response.data.message)
                fetchListRecord()
            }
            // Optionally, refresh your table or notify the user
        } catch (error) {
            toast.error('Error deleting record:', error.response.data.message);
        }
    };


    const handleButtonClick = (record, mode) => {
        setModalState({
            isVisible: true,
            mode,
            record,
        });
    };

    const closeModal = () => {
        setModalState({
            isVisible: false,
            mode: "edit",
            record: null,
        });
    };



    const columns = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     render: (_, __, index) => index + 1,
        // },
        {
            title: 'Owner',
            dataIndex: 'ownerFullName',
        },
        {
            title: 'Property Address',
            dataIndex: 'address',
        },
        {
            title: 'Amounts',
            render: (_, record) => (
                <div>
                    <p>Bill Amount: ${record.billAmount}</p>
                    <p>Current Due: ${record.currentDue}</p>
                </div>
            ),
        },
        {
            title: 'Actions',
            render: (_, record) => (
                <Space>
                    <Button
                        type="link"
                        style={{ color: "#f15927" }}
                        icon={<i className="fa fa-eye" />}
                        title="View"
                        onClick={() => handleButtonClick(record, "view")}
                    />
                    <Button
                        type="link"
                        style={{ color: "#0083ad" }}
                        icon={<i className="fa fa-edit" />}
                        title="Edit"
                        onClick={() => handleButtonClick(record, "edit")}
                    />
                    <Button
                        type="link"
                        style={{ color: "#FB404B" }}
                        icon={<i className="fa fa-trash" />}
                        title="Delete"
                        onClick={() => handleDelete([record?._id])} // Adjust ID field as needed
                    />
                </Space>
            ),
        }
    ];

    return (
        <>
            {isLoading ? (
                <FullPageLoader message="Loading records..." />
            ) : (
                <div className="container-fluid" >
                    <Row gutter={[16, 16]} style={{ display: "flex", flexDirection: "column" }}>
                        <Col span={24}>
                            <Card className='filter-card card'>
                                <Accordion>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header>Filters</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col xl={3} sm={6} className='search-wrap search-no-wrap'>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: 'black' }} className='d-block mb-2'> County </Form.Label>
                                                        <Form.Control placeholder='--- Enter County Name ---' name='county'
                                                            onKeyPress={handleKeyPress} value={ENV.capatilize(search.county)}
                                                            onChange={e => setSearch({ ...search, county: e.target.value })}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={3} sm={6} className='search-wrap search-no-wrap'>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: 'black' }} className='d-block mb-2'> Owner Name </Form.Label>
                                                        <Form.Control placeholder='--- Enter Owner Name ---' name='ownerName'
                                                            onKeyPress={handleKeyPress} value={ENV.capatilize(search.ownerName)}
                                                            onChange={e => setSearch({ ...search, ownerName: e.target.value })}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={3} sm={6} className='search-wrap search-no-wrap'>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: 'black' }} className='d-block mb-2'> Tags  <small style={{ color: '#F15927' }}> (comma separated) </small></Form.Label>
                                                        <Form.Control placeholder='--- Enter Tags ---' name='tags'
                                                            onKeyPress={handleKeyPress} value={ENV.capatilize(search.tags)}
                                                            onChange={e => setSearch({ ...search, tags: e.target.value })}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={3} sm={6} className='search-wrap search-no-wrap'>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: 'black' }} className='d-block mb-2'> Parcel Id </Form.Label>
                                                        <Form.Control placeholder='--- Enter Parcel Id ---' name='ownerName'
                                                            onKeyPress={handleKeyPress} value={search.parcelId}
                                                            onChange={e => setSearch({ ...search, parcelId: e.target.value })}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={3} sm={6} className='search-wrap search-no-wrap'>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: 'black' }} className='d-block mb-2'> Address <small style={{ color: '#F15927' }}> (City, State, zipCode or Address) </small></Form.Label>
                                                        <Form.Control placeholder='--- Enter City,State, zipCode or Address ---' name='ownerName'
                                                            onKeyPress={handleKeyPress} value={ENV.capatilize(search.address)}
                                                            onChange={e => setSearch({ ...search, address: e.target.value })}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={3} sm={6} className='search-wrap search-no-wrap'>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: 'black' }} className='d-block mb-2'> Bill Amount Range ($) </Form.Label>
                                                        <RangePicker placeholder='Bill Amount' name='billAmount' type='number'
                                                            values={{ from: search.billAmountFrom, to: search.billAmountTo }}
                                                            onChange={values => {
                                                                setSearch({ ...search, billAmountFrom: values.from, billAmountTo: values.to })
                                                            }}
                                                        ></RangePicker>
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={3} sm={6} className='search-wrap search-no-wrap'>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: 'black' }} className='d-block mb-2'> Current Due Range ($) </Form.Label>
                                                        <RangePicker placeholder='Current Due' name='billAmount' type='number'
                                                            values={{ from: search.currentDueFrom, to: search.currentDueTo }}
                                                            onChange={values => {
                                                                setSearch({ ...search, currentDueFrom: values.from, currentDueTo: values.to })
                                                            }}
                                                        ></RangePicker>
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={3} sm={6}>
                                                    <Form.Group>
                                                        <label className='d-none d-sm-block mb-2 form-label'>
                                                            &nbsp;
                                                        </label>
                                                        <div className='d-flex justify-content-between filter-btns-holder ml-20'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-info'
                                                                disabled={!searchDisabled}
                                                                onClick={onSearch}
                                                            >
                                                                Search
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='btn btn-warning'
                                                                hidden={!searchDisabled}
                                                                onClick={reset}
                                                            >
                                                                Reset
                                                            </button>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card
                                title={<span style={{ color: "#f15927" }}>Records</span>}
                                extra={
                                    <Space>
                                        <Button
                                            style={{ backgroundColor: "#FB404B", color: "#fff" }}
                                            onClick={() => handleDelete(selectedRowKeys)}
                                        >
                                            Delete Selected
                                        </Button>
                                        <Button style={{ backgroundColor: "#87CB16", color: "#fff" }}>
                                            Export Records
                                        </Button>
                                    </Space>
                                }
                            >
                                <Table
                                    dataSource={records}
                                    columns={columns}
                                    loading={isLoading}
                                    rowKey="_id" // Ensure each row has a unique key
                                    pagination={{
                                        current: pagination.current,
                                        pageSize: pagination.pageSize,
                                        total: pagination.total,
                                    }}
                                    onChange={handleTableChange} // Handle pagination changes
                                    rowSelection={{
                                        selectedRowKeys,
                                        onChange: setSelectedRowKeys,
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>

                    <EditRecordModal
                        visible={modalState.isVisible}
                        onClose={closeModal}
                        record={modalState.record}
                        mode={modalState.mode}
                        fetchListRecord={fetchListRecord}
                    />
                </div>
            )}
        </>
    );
};

export default ListOfRecord;
