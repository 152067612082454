import { Button, Table, Input, Row, Col, Space, Popover, Form, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react'
import { CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ENV } from 'config/config';
import { getTagsApi } from 'Api/apiFunctions';
import { CreateTagsApi } from 'Api/apiFunctions';
import { toast } from 'react-toastify';
import { deleteTagsApi } from 'Api/apiFunctions';

const TagsListing = () => {
    const [tags, setTags] = useState([]);
    const [title, setTitle] = useState()
    const [visible, setVisible] = useState(false);
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listId, setListId] = useState();
    const [currentTag, setCurrentTag] = useState(null);
    const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 10, total: 0 });

    const [form] = Form.useForm();



    const getAllTags = async (page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const response = await getTagsApi(page, pageSize); // Pass page and pageSize to the API

            if (response.data.success) {
                setTags(response.data.data.tags); // Update table data

                // Update pagination state
                setPagination((prev) => ({
                    ...prev,
                    current: page,
                    pageSize,
                    total: response.data.data.total, // Use total from the response
                }));
            }
        } catch (error) {
            console.error('Error fetching tags:', error.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getAllTags()
    }, [searchValue])

    const handleSubmit = async () => {
        try {
            const payload = { title }; // Common payload for both create and update
            let response;

            if (currentTag?._id) {
                // Update operation
                payload._id = currentTag._id; // Include the _id for update
                response = await axios.put(`${ENV.url}tags/edit`, payload);
            } else {
                // Create operation
                response = await CreateTagsApi(payload);
            }

            if (response.data.success) {
                toast.success(response.data.message);
                await getAllTags(); // Refresh the tags list
                setVisible(false); // Close the modal
                setTitle(''); // Reset the tag state
                form.resetFields(); // Reset the form fields
            } else {
                toast.error(response.data.message || 'Operation failed. Please try again.');
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
            console.error('Error during tag operation:', error);
        }
    };



    const handleSearch = () => {
        if (!searchValue.trim()) {
            // If search input is empty, reset to all tags
            setTags(tags);
        } else {
            // Filter tags based on search input
            const filtered = tags?.filter((tag) =>
                tag.title?.toLowerCase().includes(searchValue?.toLowerCase())
            );
            setTags(filtered);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);

        // Automatically reset or filter data when the input changes
        if (!value.trim()) {
            setTags(tags);
        }
    };

    const handleEdit = (record) => {
        setVisible(true); // Open the modal
        setTitle(record.title); // Set the tag title
        setCurrentTag(record); // Store the current tag (including _id) for update
        form.setFieldsValue({ title: record.title }); // Pre-fill the form
    };


    const showModal = (record) => {
        setIsModalVisible(true);
        setListId(record?._id)
    };

    const handleOk = async () => {
        setLoading(true);
        try {
            const tagIds = [
                listId
            ]
            const response = await axios.delete(`${ENV.url}tags/delete`, {
                data: { tagIds },
            });
            if (response.data.success) {
                toast.success(response.data.message);
                getAllTags();
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error('An error occurred while deleting.');
        } finally {
            setLoading(false);
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const openCreateModal = () => {
        setVisible(true); // Open the modal
        setTitle(''); // Reset the title
        setCurrentTag(null); // Clear the current tag (no _id for create)
        form.resetFields(); // Reset the form fields
    };


    const columns = [
        {
            title: "Tag Name",
            dataIndex: "title",
            key: "title",
            align: "center",
            render: (text) => (text ? <span>{text}</span> : <span>-</span>),
        },
        {
            title: "Properties",
            dataIndex: "properties",
            key: "properties",
            align: "center",
            render: (text) => (text ? <span>{text}</span> : <span>0</span>),
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => showModal(record)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];




    return (
        <div>

            <Row
                gutter={[12, 12]}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap", // Ensures wrapping if needed
                }}
            >
                <Col xs={24} sm={8} style={{ textAlign: "left" }}>
                    <Title level={1} style={{ color: "#f15927", margin: 0 }}>Tags</Title>
                </Col>

                <Col xs={24} sm={12} style={{ display: 'flex', gap: '5px', flexWrap: "nowrap" }}>
                    <Input
                        type="search"
                        placeholder="Search Tag"
                        value={searchValue}
                        onChange={handleInputChange}
                        onPressEnter={handleSearch}
                        style={{ flex: 1 }} // Ensures the input takes available space
                    />
                    <Button
                        type="primary"
                        size="large"
                        onClick={handleSearch}
                        style={{ whiteSpace: "nowrap" }} // Prevents breaking text in buttons
                    >
                        Search
                    </Button>
                </Col>

                <Col xs={24} sm={4} style={{ textAlign: "right" }}>
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: "#f15927",
                            whiteSpace: "nowrap",
                        }}
                        icon={<CheckOutlined />}
                        onClick={openCreateModal}
                    >
                        Add New Tag
                    </Button>
                </Col>
            </Row>
            <Table
                loading={loading}
                dataSource={tags}
                bordered
                columns={columns}
                pagination={{
                    current: pagination.current, // Current page
                    pageSize: pagination.pageSize, // Items per page
                    total: pagination.total, // Total number of items
                    onChange: (page, pageSize) => {
                        // Update the pagination state and fetch data
                        setPagination((prev) => ({
                            ...prev,
                            current: page,
                            pageSize,
                        }));
                        getAllTags(page, pageSize); // Fetch data for the selected page and size
                    },
                }}
            />


            <Modal
                title={currentTag ? "Edit Tag" : "Add New Tag"}
                open={visible}
                onCancel={() => {
                    setVisible(false);
                    form.resetFields();
                }}
                footer={null}
                destroyOnClose
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        label="Tag Name"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Please input the tag name!",
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item style={{ display: "flex", justifyContent: "end" }}>
                        <Button type="primary" htmlType="submit">
                            {currentTag ? "Update" : "Submit"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Confirm Deletion"
                open={isModalVisible}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={handleCancel}
                okText="Yes, Delete"
                okType="danger"
                cancelText="No"
            >
                <p>Are you sure you want to delete this Tag?</p>
            </Modal>
        </div>
    )
}

export default TagsListing