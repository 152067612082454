import { Button, Table, Input, Row, Col, Space, Popover, Modal, DatePicker, Form, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react'
import { CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ENV } from 'config/config';
import { toast } from 'react-toastify';
import { getListApi } from 'Api/apiFunctions';
import dayjs from "dayjs";
import { postListApi } from 'Api/apiFunctions';
import ListingImportModal from './ListingImportModal';
import { useNavigate } from 'react-router';


const ListingPage = () => {
    const [isButtonEnabled, setIsButtonEnabled] = useState(false)
    const [listData, setListData] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [importModal, setImportModal] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listId, setListId] = useState();
    const [isAddNew, setIsAddNew] = useState(false);
    const navigate = useNavigate();

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        form.resetFields(); // Reset form fields when the modal is closed
    };

    const getAllTags = async () => {
        setLoading(true);
        try {
            const response = await getListApi()

            if (response.data.success) {
                setListData(response.data.data?.propertyLists)
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        getAllTags()
    }, [])


    const showModal = (record) => {
        setIsModalVisible(true);
        setListId(record?._id)
    };

    const handleOk = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${ENV.url}propertyList/delete/${listId}`);
            if (response.data.success) {
                toast.success(response.data.message);
                getAllTags();
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error('An error occurred while deleting.');
        } finally {
            setLoading(false);
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const handleNameClick = (record, keyName) => {
        navigate('/record-lists', {
            state: {
                recordId: record?._id,
                keyName,
            },
        });
    };

    // const d = (record) => {
    //     console.log("dsfdsfsd", record)
    //     navigate(`/record-lists`, { state: { recordId: record?._id,  } });
    // };


    const columns = [
        {
            title: "List Name",
            dataIndex: "name",
            key: "name",
            align: "center",
            render: (text, record) => (
                text ? (
                    <span
                        style={{ cursor: "pointer", }}
                        onClick={() => handleNameClick(record, 'listName')}
                    >
                        {text}
                    </span>
                ) : (
                    <span>-</span>
                )
            ),
        },
        {
            title: "Properties",
            dataIndex: "properties",
            key: "properties",
            align: "center",
            render: (text, record) => (
                text ? (
                    <span
                        style={{ cursor: "pointer", }}
                        onClick={() => handleNameClick(record, 'matchRecord')}
                    >
                        {text}
                    </span>
                ) : (
                    <span>-</span>
                )
            ),
        },
        {
            title: "Purchased From",
            dataIndex: "purchasedFrom",
            key: "purchasedFrom",
            align: "center",
            render: (text) => (text ? <span>{text}</span> : <span>-</span>),
        },
        {
            title: "Purchased Date",
            dataIndex: "purchasedDate",
            key: "purchasedDate",
            align: "center",
            render: (text) => (text ? <span>{dayjs(text).format('MM/DD/YYYY')}</span> : <span>-</span>),
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => (
                <Space>
                    <Button
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => showModal(record)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];


    const listNameOptions = listData?.map((data) => {
        return { label: data.name, value: data._id };
    })

    const handleFormChange = () => {
        // Check if both required fields have values
        const name = form.getFieldValue("name");
        const purchasedDate = form.getFieldValue("purchasedDate");
        setIsButtonEnabled(!!name && !!purchasedDate); // Enable the button if both fields are filled
    };

    const handleAddNewClick = () => {
        setIsAddNew(true); // Show form fields for "Add New"
    };

    // const handleAddExistingClick = () => {
    //     const values = form.getFieldsValue();
    //     console.log("Importing records with values:", values);
    //     // Your import records logic here
    // };

    const handleImportRecordsClick = () => {
        const values = form.getFieldsValue();
        setImportModal(true); // Open the import modal
    };

    const handleModalOpen = () => {
        form.resetFields(); // Reset form fields
        setIsButtonEnabled(false); // Disable the button initially
    };

    return (
        <div>
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
                <div >
                    <Title level={1} style={{ color: "#f15927", margin: 0 }}>Lists</Title>
                </div>
                <Button
                    type="primary"
                    className="mb-0 text-nowrap"
                    style={{ backgroundColor: "#f15927" }}
                    onClick={handleOpenModal}
                >
                    Add List
                </Button>
                {/* <ListingImportModal importModal={importModal} setImportModal={setImportModal} /> */}
            </Space>
            <Table
                loading={loading}
                dataSource={listData || []}
                pagination={{ pageSize: 10 }}
                bordered
                columns={columns}
            />


            <Modal
                title="Purchase Details"
                open={isModalOpen}
                onCancel={handleCloseModal}
                afterOpenChange={(isOpen) => {
                    if (isOpen) {
                        handleModalOpen();
                        setIsAddNew(false); // Reset to default state when modal opens
                    }
                }}
                footer={null}
            >
                <Form form={form} layout="vertical">
                    {/* Select Input */}
                    <Form.Item label="Select List" name="selectedListId">
                        <Select
                            placeholder="Select a List"
                            size="large"
                            options={listNameOptions} 
                            disabled={isAddNew}// Pass the list of options dynamically
                        />
                    </Form.Item>

                    {/* Buttons for Add New and Add Existing */}
                    <Form.Item>
                        <Space className='d-flex justify-content-end'>
                            <Button
                                type="primary"
                                onClick={handleAddNewClick}
                                // disabled={isAddNew} // Disable the button when already in "Add New" mode
                                style={{backgroundColor:"#f15927"}}
                            >
                                Add New
                            </Button>
                            <Button
                                type="default"
                                onClick={handleImportRecordsClick}
                                disabled={isAddNew}
                                 // Disable when "Add New" is active
                            >
                                Add Existing
                            </Button>
                        </Space>
                    </Form.Item>

                    {/* Show Form Fields for Add New */}
                    {isAddNew && (
                        <>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: "Please enter the name" }]}
                            >
                                <Input onChange={handleFormChange} placeholder="Enter name" size="large" />
                            </Form.Item>

                            <Form.Item
                                label="Purchased From"
                                name="purchasedFrom"
                                rules={[{ required: true, message: "Please enter the place of purchase" }]}
                            >
                                <Input placeholder="Enter place of purchase" size="large" />
                            </Form.Item>

                            <Form.Item
                                label="Purchased Date"
                                name="purchasedDate"
                                rules={[{ required: true, message: "Please select the purchase date" }]}
                            >
                                <DatePicker onChange={handleFormChange} style={{ width: "100%" }} size="large" />
                            </Form.Item>

                            {/* Submit Button */}
                            <Form.Item className='d-flex justify-content-end'>
                                <Button
                                    type="primary"
                                    onClick={handleImportRecordsClick}
                                    disabled={!isButtonEnabled}
                                    style={{backgroundColor:"#f15927"}}
                                >
                                    Import Records
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Modal>


            {/* <Modal
                title="Purchase Details"
                open={isModalOpen}
                onCancel={handleCloseModal}
                afterOpenChange={(isOpen) => {
                    if (isOpen) handleModalOpen(); // Reset form and button when modal opens
                }}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: "Please enter the name" }]}
                    >
                        <Input placeholder="Enter name" size="large" />
                    </Form.Item>

                    <Form.Item label="Purchased From" name="purchasedFrom">
                        <Input placeholder="Enter place of purchase" size="large" />
                    </Form.Item>

                    <Form.Item
                        label="Purchased Date"
                        name="purchasedDate"
                        rules={[{ required: true, message: "Please select the purchase date" }]}
                    >
                        <DatePicker style={{ width: "100%" }} size="large" />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            className="mb-0 text-nowrap"
                            onClick={handleImportRecordsClick}
                            disabled={!isButtonEnabled} // Disable the button if fields are incomplete
                        >
                            Import Records
                        </Button>
                    </Form.Item>
                </Form>

            </Modal> */}
            {importModal && (
                <ListingImportModal
                    importModal={importModal}
                    setImportModal={setImportModal}
                    formData={form.getFieldsValue()} // Pass form data to ListingImportModal
                    handleCloseModal={handleCloseModal}
                    getAllLists={getAllTags}
                />
            )}

            <Modal
                title="Confirm Deletion"
                open={isModalVisible}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={handleCancel}
                okText="Yes, Delete"
                okType="danger"
                cancelText="No"
            >
                <p>Are you sure you want to delete the property List?</p>
            </Modal>
        </div>
    )
}

export default ListingPage