import { CREATE_TAGS, DELETE_RECORD, DELETE_TAGS, GET_ALL_LIST, GET_ALL_MATCH_RECORD_LIST, GET_ALL_RECORD_LIST, GET_LIST, GET_TAGS, POST_LIST, UPDATE_RECORD } from "./apiPath"
import axios from "axios"


const authorizedAxiosGet = async (url, params = {}) => {
  try {
    const response = await axios.get(url, { params }); // Pass query parameters to the request
    return response;
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
}


const authorizedAxiosPost = async (url, data) => {
  try {
    const response = await axios.post(url, data,)
    return response
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}


const authorizedAxiosDelete = async (url, id) => {
  try {
    //   const formattedUrl = `${url}/${id}`
    const response = await axios.delete(url, id)
    return response
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}


const authorizedAxiosGetById = async (url, id, page, limit, filters = {}) => {
  try {
      // Convert filters object to query string
      const filterParams = new URLSearchParams(filters).toString();
      const formattedUrl = `${url}?propertyList=${id}&page=${page}&limit=${limit}&${filterParams}`;
      const response = await axios.get(formattedUrl);
      return response;
  } catch (error) {
      console.error('Error:', error);
      throw error;
  }
};


const authorizedAxiosBodyDelete = async (url, data) => {
  try {
    const response = await axios.delete(url, {
      data, // Pass the body payload here
    });
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
const authorizedAxiosUpdate = async (url, data) => {
  try {
    const response = await axios.put(url, {
      data, // Pass the body payload here
    });
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};




// Get tag
export const getTagsApi = async (page = 1, limit = 10) => {
  return authorizedAxiosGet(GET_TAGS, { page, limit }); // Pass pagination params
};
export const CreateTagsApi = async (data) => {
  return authorizedAxiosPost(CREATE_TAGS, data)
}
export const deleteTagsApi = async (data) => {
  return authorizedAxiosDelete(DELETE_TAGS, data)
}

// get List of Properties

export const getListApi = async () => {
  return authorizedAxiosGet(GET_LIST)
}
export const getAllListApi = async () => {
  return authorizedAxiosGet(GET_ALL_LIST)
}
export const getAllRecordListApi = async (id, page, limit, filters) => {
  return authorizedAxiosGetById(GET_ALL_RECORD_LIST, id, page, limit, filters);
};

export const getAllMatchRecordListApi = async (id, page, limit, filters) => {
  return authorizedAxiosGetById(GET_ALL_MATCH_RECORD_LIST, id, page, limit, filters);
};
export const postListApi = async (data) => {
  return authorizedAxiosPost(POST_LIST, data)
}
export const deleteRecordApi = async (data) => {
  return authorizedAxiosBodyDelete(DELETE_RECORD, data)
}
export const updateRecordApi = async (data) => {
  return authorizedAxiosUpdate(UPDATE_RECORD, data)
}
