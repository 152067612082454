import {
  Button,
  Table,
  Input,
  Row,
  Col,
  Space,
  Popover,
  Modal,
  DatePicker,
  Form,
  Select,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import {
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ENV } from "config/config";
import { toast } from "react-toastify";
import { getListApi } from "Api/apiFunctions";
import dayjs from "dayjs";
import { postListApi } from "Api/apiFunctions";
// import ListingImportModal from './ListingImportModal';
import { useNavigate } from "react-router";
import { data } from "jquery";

const ScraperPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listId, setListId] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [scraperData, setScraperData] = useState();
  const [enabledDownloads, setEnabledDownloads] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    form.resetFields(); // Reset form fields when the modal is closed
  };

  const showModal = (record) => {
    setIsModalVisible(true);
    setListId(record?._id);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const payload = { ids: [listId] }; // Construct the payload
      const response = await axios.delete(`${ENV.url}scraper/`, {
        data: payload, // Use `data` to send the body
      });
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to delete");
    } finally {
      setLoading(false);
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleScraperRun = async (url) => {
    setLoading(true);
    try {
      const response = await axios.post(`${ENV.url}scraper/run`, {
        url: url.url,
      });

      if (response?.data?.success) {
        toast.success(response.data?.message);

        const filePath = response.data.filePath;
        if (filePath) {
          const fileName = filePath.split(/[/\\]/).pop();
          const fileDownloadUrl = `${ENV.url}download?fileName=${fileName}`;
          setFileUrl(filePath); // Enable the download button
        }
      } else {
        toast.error("Failed to start the scraper.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while running the scraper."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileDownload = () => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      render: (text) => (text ? <span>{text}</span> : <span>-</span>),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      align: "center",
      render: (text) => (text ? <span>{text}</span> : <span>-</span>),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            danger
            icon={<DownloadOutlined />}
            onClick={() => handleScraperRun(text)}
          >
            Scrap Data
          </Button>
          <Button
            type="primary"
            danger
            icon={<DownloadOutlined />}
            disabled={!fileUrl}
            onClick={handleFileDownload}
          >
            Download
          </Button>
        </Space>
      ),
    },
  ];

  const urlData = [
    {
      title: "Orange County North Carolina",
      url: "https://web.co.orange.nc.us/publicwebaccess/BillDelinquentSearch.aspx",
    },
    {
      title: "Henderson County North Carolina",
      url: "https://bcpwa.ncptscloud.com/hendersontax/BillDelinquentSearch.aspx",
    },
    {
      title: "Mecklenburg North Carolina",
      url: "https://taxbill.co.mecklenburg.nc.us/publicwebaccess/BillDelinquentSearch.aspx",
    },
    {
      title: "Cumberland North Carolina",
      url: "https://taxpwa.co.cumberland.nc.us/publicwebaccess/BillDelinquentSearch.aspx",
    },
    // {
    //     title: "Johnston County  TaxPay Online",
    //     url: "https://taxpay.johnstonnc.com/TaxPayOnline/prod/"
    // },
    {
      title: "Johnston County  TaxPay Online",
      url: "https://taxpay.johnstonnc.com/TaxPayOnline/prod/",
    },
  ];

  const handlePostData = async (value) => {
    debugger;
    setLoading(true);
    try {
      const data = {
        title: value?.selectedListId,
        url: value?.selectedListId,
      };
      const response = await axios.post(`${ENV.url}scraper`, data);
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };
  const listNameOptions = urlData?.map((data) => {
    return { label: data.url, value: data.title };
  });

  useEffect(() => {
    const fetchData = async (page = 1, pageSize = 10) => {
      try {
        const response = await axios.get(`${ENV.url}scraper`, page, pageSize);
        if (response.data.success) {
          // toast.success(response.data.message);
          setScraperData(response.data.data.scrapers);
          setPagination((prev) => ({
            ...prev,
            current: page,
            pageSize,
            total: response.data.data.scrapers, // Use total from the response
          }));
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchData();
  }, [loading]);

  return (
    <div>
      <Space style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Title level={1} style={{ color: "#f15927", margin: 0 }}>
            Scraper
          </Title>
        </div>
        {/* <Button
                    type="primary"
                    className="mb-0 text-nowrap"
                    style={{ backgroundColor: "#f15927" }}
                    onClick={handleOpenModal}
                >
                    Add Scraper
                </Button> */}
      </Space>
      <Table
        loading={loading}
        dataSource={urlData}
        pagination={{ pageSize: 10 }}
        bordered
        columns={columns}
      />

      <Modal
        title="Scraper Details"
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handlePostData}>
          {/* Select Input */}
          <Form.Item label="Select URL" name="selectedListId">
            <Select
              placeholder="Select a URL"
              size="large"
              options={listNameOptions}
              dropdownStyle={{ maxHeight: "95px", overflow: "auto" }}
            />
          </Form.Item>

          {/* Buttons for Add New and Add Existing */}
          <Form.Item>
            <Space className="d-flex justify-content-end">
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#f15927" }}
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Confirm Deletion"
        open={isModalVisible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Yes, Delete"
        okType="danger"
        cancelText="No"
      >
        <p>Are you sure you want to delete the property List?</p>
      </Modal>
    </div>
  );
};

export default ScraperPage;
