import { ENV } from "config/config";



// Tags Api

export const GET_TAGS = `${ENV.url}tags/get-all-tags`
export const CREATE_TAGS = `${ENV.url}tags/create`
export const DELETE_TAGS = `${ENV.url}tags/delete-tags`


// Property List Api

export const GET_LIST = `${ENV.url}propertyList`
export const POST_LIST = `${ENV.url}propertyList/add`
export const GET_ALL_LIST = `${ENV.url}propertyList/all`
export const GET_ALL_RECORD_LIST = `${ENV.url}propertyList/search-list-records`
export const GET_ALL_MATCH_RECORD_LIST = `${ENV.url}propertyList/search-property-records`
export const DELETE_RECORD = `${ENV.url}propertyList/delete-list-records`
export const UPDATE_RECORD = `${ENV.url}propertyList/edit-list-record`